import React, { useEffect, useState } from 'react'
import { useParams, useHistory, Redirect } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state'
import dayjs from 'dayjs'
import moment from 'moment'
import { SaveOutlined } from '@ant-design/icons'
import { message, Input, Button, DatePicker, Select, Tooltip } from 'antd'

import StyledBox from 'components/common/styled-components/StyledBox'
import StyledFitmentDetails from 'components/common/styled-components/StyledFitmentDetails'
import CandidateCard from 'components/CandidateCard'
import FitmentsService from 'services/users/fitments.service'
import { staleWhileRevalidate } from 'utils/render-strategies'

import StyledFitmentItemCard from 'components/common/styled-components/StyledFitmentItemCard'

import globalStore from 'store/index'
import currencies from 'constants/currency'
import { hideStandardFields } from 'utils/fitment'
import { MONTHLY_DEMINIMIS_ALLOWANCES } from 'constants/fitment'
import { strftimeTOMomentFormat } from 'utils/strftime-to-moment-format'
import CountryDetailsService from 'services/users/country-details.service'

const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

const frequencies = [
	{ value: 'hourly', label: 'Hourly' },
	{ value: 'daily', label: 'Daily' },
	{ value: 'bi_weekly', label: 'Bi Weekly' },
	{ value: 'semi_monthly', label: 'Semi Monthly' },
	{ value: 'monthly', label: 'Monthly' },
	{ value: 'quarterly', label: 'Quarterly' },
	{ value: 'half_yearly', label: 'Half-Yearly' },
	{ value: 'annually', label: 'Annually' },
]

const FitmentsEditPage = () => {
	const history = useHistory()
	const { fitmentId } = useParams()
	const pageState = globalStore.ui.fitments.edit
	const [fitment, setFitment] = useState(null)
	const [ctc, setCtc] = useState(null)
	const [previous_ctc_fixed, setPreviousCtcFixed] = useState(null)
	const [previous_ctc_variable, setPreviousCtcVariable] = useState(null)
	const [fitmentFields, setFitmentFields] = useState([])
	const [saving, setSaving] = useState(false)
	const [joiningDate, setJoiningDate] = useState(null)
	const [joiningBonus, setJoiningBonus] = useState(null)
	const [rate, setRate] = useState(null)
	const [frequency, setFrequency] = useState(null)
	const [dateFormat, setDateFormat] = useState(null)
	const [defaultFrequency, seDefaultFrequency] = useState('hourly')
	const [disableFrequency, seDisableFrequency] = useState(false)
	const { TextArea } = Input

	useEffect(() => {
		const fetchData = async () => {
			const pageState = globalStore.ui.fitments.edit
			await FitmentsService.show({ pageState, fitmentId })
			const countryDetailsPageState = globalStore.ui.configuration.countryDetails.index
			await CountryDetailsService.index({ pageState: countryDetailsPageState })

			const { fitment } = pageState
			const { applicable_fitment_fields } = fitment

			if (fitment) {
				const fitmentFields = applicable_fitment_fields
				setFitmentFields(fitmentFields)
				setDateFormat(strftimeTOMomentFormat(fitment?.date_format))
				setFitment(fitment)
				setDateFormat(strftimeTOMomentFormat(fitment?.dateformat))
				setJoiningDate(fitment.joining_date)
				setCtc(fitment.ctc)
				setJoiningBonus(fitment.joining_bonus)
				setPreviousCtcFixed(fitment.previous_ctc_fixed)
				setPreviousCtcVariable(fitment.previous_ctc_variable)
				setRate(fitment.rate)
				setFrequency(fitment?.frequency)

				const { serverStatus, countriesDetailErrors, country_details } = countryDetailsPageState

				if (serverStatus.status !== 200) {
					message.error('Oops!! something went wrong')
					return
				}

				if (countriesDetailErrors) {
					message.error(countriesDetailErrors[0] || "Couldn't fetch Country Details")
					return
				}
				const getCountryDetails = country_details.find(
					(data) => data.name === fitment?.country
				)?.details

				const getDefaultFrequency = getCountryDetails?.find(
					(data) => data.name === 'Default Frequency'
				)?.value
				const getDisableFrequency = getCountryDetails?.find(
					(data) => data.name === 'Disable Frequency'
				)?.value

				seDefaultFrequency(getDefaultFrequency || 'hourly')
				seDisableFrequency(getDisableFrequency || false)

				return
			}

			message.error('Failed to load fitments')
		}

		fetchData()
	}, [fitmentId])

	const handleUpdate = async () => {
		if (!ctc) {
			message.error("Fixed Compensation Offered can't be blank.")
			return
		}
		// if (!previous_ctc_fixed) {
		//   message.error("Previous Fixed Compensation can't be blank.")
		//   return
		// }

		// const fitmentFieldsKeys = Object.keys(fitmentFields)

		const tempState = {}

		const { applicable_fitment_fields_configurations } = pageState.fitment

		const requiredFields = applicable_fitment_fields_configurations.filter((aff) => {
			if (aff.parent_variable_id) {
				const parentFitmentField =
					fitmentFields.find(
						(ff) => ff.fitment_fields_configuration_id === aff.parent_variable_id
					) || {}

				if (parentFitmentField) {
					const parentVariableValue = aff.parent_variable_value?.map((data) => data?.toLowerCase())

					if (!parentVariableValue?.includes(parentFitmentField?.value?.toLowerCase())) return false
				}

				// if (
				//   parentFitmentField?.value?.toLowerCase() !==
				//   aff.parent_variable_value?.toLowerCase()
				// )
				//   return false
			}

			return aff.field_required
		})

		const requiredFieldsMap = requiredFields.map((rf) => {
			const fitmentField = fitmentFields.find((ff) => ff.fitment_fields_configuration_id === rf.id)

			return {
				...rf,
				...fitmentField,
			}
		})

		const pendingRequiredFields = requiredFieldsMap.filter((rf) => rf.field_required && !rf.value)

		if (pendingRequiredFields.length > 0) {
			message.error('Please fill required fitment fields to update')
			return
		}

		let tempFitmentFields = fitmentFields

		// PHL MONTHLY DE-MINIMIS ALLOWANCES
		const pmdma = applicable_fitment_fields_configurations.find(
			(affc, index) => affc.name.toUpperCase() === 'PHL MONTHLY DE-MINIMIS ALLOWANCES'
		)

		if (pmdma) {
			tempFitmentFields.push({
				fitmentFieldsConfigurationId: pmdma.id,
				name: pmdma.name,
				fieldRequired: pmdma.field_required,
				value: MONTHLY_DEMINIMIS_ALLOWANCES,
				dataType: pmdma.data_type,
				fieldType: pmdma.field_type,
			})
		}

		const postData = {
			fitment: {
				ctc,
				previous_ctc_fixed,
				previous_ctc_variable,
				joining_date: joiningDate,
				joining_bonus: joiningBonus,
				fitment_fields: tempFitmentFields,
				rate,
				frequency: frequency || defaultFrequency,
			},
		}

		setSaving(true)
		await FitmentsService.update({
			pageState: tempState,
			fitmentId,
			values: postData,
		})
		setSaving(false)

		const { fitment, fitmentErrors } = tempState

		if (fitmentErrors) {
			message.error(fitmentErrors[0] || 'Opps!! something went wrong.')
			return
		}

		message.success('Fitment fields updated.')
		setFitmentFields(fitment['fitment_fields'])
		setFitment(fitment)
		setJoiningDate(fitment.joining_date)
		setCtc(fitment.ctc)
		setJoiningBonus(fitment.joining_bonus)
		setPreviousCtcFixed(fitment.previous_ctc_fixed)
		setPreviousCtcVariable(fitment.previous_ctc_variable)
		setRate(fitment.rate)
		setFrequency(fitment.frequency)

		history.push(`/fitments/${fitmentId}`)
	}

	const handleCTCChange = (e) => {
		setCtc(e.target.value)
	}

	const handleJoiningBonusChange = (e) => {
		setJoiningBonus(e.target.value)
	}

	// const handleDateChange = (e) => {
	//   setJoiningDate(e.target.value)
	// }

	const handlePreviousCTCFixedChange = (e) => {
		const value = e.target.value
		setPreviousCtcFixed(value)
	}

	const handleRateChange = (e) => {
		const value = e.target.value
		setRate(value)
		setCtc(e.target.value)
	}

	const handleFrequencyChange = (value) => {
		setFrequency(value)
	}

	const handlePreviousCTCVariableChange = (e) => {
		const value = e.target.value
		setPreviousCtcVariable(value)
	}

	const getFilteredFitmentField = (updatedFitmentFields) => {
		const { applicable_fitment_fields_configurations } = fitment

		let fitmentFields = updatedFitmentFields

		const ignoredFitmentFieldIds = []

		const getFilteredFitmentFields = fitmentFields.filter((ff) => {
			const applicableFitmentFieldConfiguration = applicable_fitment_fields_configurations.find(
				(affc) => affc.id === ff.fitment_fields_configuration_id
			)

			const parentFitmentField = fitmentFields.find(
				(f) =>
					f.fitment_fields_configuration_id ===
					applicableFitmentFieldConfiguration.parent_variable_id
			)

			if (ignoredFitmentFieldIds.includes(applicableFitmentFieldConfiguration.parent_variable_id)) {
				ignoredFitmentFieldIds.push(applicableFitmentFieldConfiguration.id)
				return false
			}

			if (!applicableFitmentFieldConfiguration.parent_variable_id) return true

			if (parentFitmentField) {
				const parentVariableValue = applicableFitmentFieldConfiguration.parent_variable_value?.map(
					(data) => data?.toLowerCase()
				)

				if (parentVariableValue?.includes(parentFitmentField?.value?.toLowerCase())) return true
			}

			ignoredFitmentFieldIds.push(applicableFitmentFieldConfiguration.id)
			return false
		})
		return getFilteredFitmentFields
	}

	const handleFitmentFieldsInputChange = ({
		fitmentFieldsConfigurationId,
		name,
		fieldRequired,
		value,
		dataType,
		fieldType,
	}) => {
		const clonedFitmentFields = JSON.parse(JSON.stringify(fitmentFields))

		const fitmentFieldExist = fitmentFields.some(
			(ff) => ff.fitment_fields_configuration_id === fitmentFieldsConfigurationId
		)

		let updatedFitmentFields = clonedFitmentFields

		if (fitmentFieldExist) {
			updatedFitmentFields = clonedFitmentFields.map((cff) => {
				if (cff.fitment_fields_configuration_id === fitmentFieldsConfigurationId) {
					return { ...cff, value }
				}

				return cff
			})
		} else {
			updatedFitmentFields = [
				...clonedFitmentFields,
				{
					fitment_fields_configuration_id: fitmentFieldsConfigurationId,
					name,
					field_required: fieldRequired,
					type: fieldType,
					value: dataType === 'decimal' ? parseFloat(value || 0) : value,
				},
			]
		}

		setFitmentFields(getFilteredFitmentField(updatedFitmentFields))
	}

	const frequenciesOptions = () => (
		<Select
			value={frequency || defaultFrequency}
			onChange={handleFrequencyChange}
			style={{ width: 150 }}
			className='select-after'
			disabled={disableFrequency}>
			{frequencies.map((f, index) => (
				<Select.Option key={index} value={f.value}>
					<Tooltip placement='rightTop' title={f.label}>
						<div className='tooltip'>{f.label}</div>
					</Tooltip>
				</Select.Option>
			))}
		</Select>
	)

	const renderInput = (ffc, fitmentField) => {
		if (ffc.data_type === 'date') {
			return (
				<DatePicker
					placeholder={dateFormat}
					format={dateFormat}
					value={fitmentField.value ? moment(fitmentField.value, dateFormat) : null}
					disabled={saving}
					onChange={(date, dateString) => {
						handleFitmentFieldsInputChange({
							fitmentFieldsConfigurationId: ffc.id,
							name: ffc.name,
							fieldRequired: ffc.field_required,
							value: dateString,
							dataType: ffc.data_type,
							fieldType: ffc.field_type,
						})
					}}
				/>
			)
		}

		if (ffc.data_type === 'list') {
			return (
				<Select
					value={fitmentField.value}
					onChange={(value) =>
						handleFitmentFieldsInputChange({
							fitmentFieldsConfigurationId: ffc.id,
							name: ffc.name,
							fieldRequired: ffc.field_required,
							value: value,
							dataType: ffc.data_type,
							fieldType: ffc.field_type,
						})
					}>
					{ffc.list_options?.map((v, index) => (
						<Select.Option value={v} key={index}>
							<Tooltip placement='rightTop' title={v}>
								<div className='tooltip'>{v}</div>
							</Tooltip>
						</Select.Option>
					))}
				</Select>
			)
		}

		if (ffc.data_type === 'text-area') {
			return (
				<TextArea
					value={fitmentField.value}
					placeholder={`Enter ${ffc.name}`}
					rows={4}
					onChange={(e) =>
						handleFitmentFieldsInputChange({
							fitmentFieldsConfigurationId: ffc.id,
							name: ffc.name,
							fieldRequired: ffc.field_required,
							value: e.target.value,
							dataType: ffc.data_type,
							fieldType: ffc.field_type,
						})
					}
				/>
			)
		}

		return (
			<Input
				value={fitmentField.value}
				placeholder={`Enter ${ffc.name}`}
				disabled={saving}
				addonBefore={ffc.name === 'Retention Bonus' ? currencies.INR : null}
				min='0'
				type={ffc.data_type === 'decimal' ? 'number' : 'text'}
				onChange={(e) =>
					handleFitmentFieldsInputChange({
						fitmentFieldsConfigurationId: ffc.id,
						name: ffc.name,
						fieldRequired: ffc.field_required,
						value: e.target.value,
						dataType: ffc.data_type,
						fieldType: ffc.field_type,
					})
				}
			/>
		)
	}

	const renderFitmentFieldsWithCTC = () => {
		let rows = []

		const hideStandardFieldsObject = hideStandardFields({ fitment })

		const { applicable_fitment_fields_configurations } = fitment

		rows = applicable_fitment_fields_configurations.map((ffc, i) => {
			const fitmentField =
				fitmentFields.find((ff) => ff.fitment_fields_configuration_id === ffc.id) || {}

			if (ffc.parent_variable_id && fitmentFields.length === 0) {
				return null
			}
			if (ffc.parent_variable_id) {
				const parentFitmentField = fitmentFields.find(
					(ff) => ff.fitment_fields_configuration_id === ffc.parent_variable_id
				)
				if (!parentFitmentField) {
					return null
				}
				const parentVariableValue = ffc.parent_variable_value?.map((data) => data?.toLowerCase())

				if (!parentVariableValue?.includes(parentFitmentField?.value?.toLowerCase())) {
					return null
				}
			}

			if (ffc.name.toUpperCase() === 'PHL MONTHLY DE-MINIMIS ALLOWANCES') {
				return (
					<div className='item-row fitment-field' key={`ff-${i}`}>
						<div className='label'>{ffc.name}</div>
						<div>{MONTHLY_DEMINIMIS_ALLOWANCES}</div>
					</div>
				)
			}

			return (
				<div className='item-row fitment-field' key={`ff-${i}`}>
					<div className='label'>
						{ffc.name}
						{ffc.field_required && <span style={{ color: 'red' }}>*</span>}
					</div>
					<div style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
						{renderInput(ffc, fitmentField)}
						{ffc.name === 'Restricted Stock Units' && (
							<p style={{ margin: 0 }}>(Only Applicable for Role Band D+)</p>
						)}
					</div>
				</div>
			)
		})

		return (
			<StyledFitmentItemCard>
				<div className='header'>
					<h1>Candidate Salary Data</h1>
				</div>
				{/* <div className="item-list">
          <div className="item-row fitment-field" key="ff-jd">
            <div className="label">Joining Date</div>
            <div>
              <Input
                value={dayjs(joiningDate).format("DD-MMM-YYYY")}
                placeholder={`Enter Joining Date`}
                type="date"
                onChange={handleDateChange}
              />
            </div>
          </div>
        </div> */}
				{!hideStandardFieldsObject['rate'] && (
					<div className='item-list'>
						<div className='item-row fitment-field' key='ff-rate'>
							<div className='label'>Rate</div>
							<div>
								<Input
									value={rate}
									placeholder={`Enter the rate`}
									disabled={saving}
									type='number'
									min='0'
									addonAfter={frequenciesOptions()}
									onChange={handleRateChange}
								/>
							</div>
						</div>
					</div>
				)}
				{!hideStandardFieldsObject['offered_fixed_compensation'] && (
					<div className='item-list'>
						<div className='item-row fitment-field' key='ff-ctc'>
							<div className='label'>Offered Fixed Compensation</div>
							<div>
								<Input
									value={ctc}
									placeholder={`Enter the Fixed Compensation Offered`}
									disabled={saving || !!rate || !hideStandardFieldsObject['rate']}
									type='number'
									min='0'
									// addonBefore={currencies.INR}
									onChange={handleCTCChange}
								/>
							</div>
						</div>
					</div>
				)}
				{!hideStandardFieldsObject['previous_fixed_compensation'] && (
					<div className='item-list'>
						<div className='item-row fitment-field' key='ff-prev-ctc'>
							<div className='label'>Previous Fixed Compensation</div>
							<div>
								<Input
									value={previous_ctc_fixed}
									placeholder={`Enter the Previous Fixed Compensation`}
									disabled={saving}
									type='number'
									min='0'
									// addonBefore={currencies.INR}
									onChange={handlePreviousCTCFixedChange}
								/>
							</div>
						</div>
					</div>
				)}

				{!hideStandardFieldsObject['previous_variable_compensation'] && (
					<div className='item-list'>
						<div className='item-row fitment-field' key='ff-prev-ctc-variable'>
							<div className='label'>Previous Variable Compensation</div>
							<div>
								<Input
									value={previous_ctc_variable}
									placeholder={`Enter the Previous Variable Compensation`}
									disabled={saving}
									type='number'
									min='0'
									// addonBefore={currencies.INR}
									onChange={handlePreviousCTCVariableChange}
								/>
							</div>
						</div>
					</div>
				)}
				<div className='item-list'>
					<div className='item-row fitment-field' key='ff-jd'>
						<div className='label'>Joining Bonus</div>
						<div>
							<Input
								value={joiningBonus}
								type='number'
								min='0'
								disabled={saving}
								// addonBefore={currencies.INR}
								placeholder={`Enter Joining Bonus`}
								onChange={handleJoiningBonusChange}
							/>
						</div>
					</div>
				</div>
				<div className='item-list'>{rows}</div>
			</StyledFitmentItemCard>
		)
	}

	const renderPageContent = () => {
		return (
			<StyledFitmentDetails editable>
				<CandidateCard {...fitment['candidate']} />

				<div className='card fitment-fields'>
					{renderFitmentFieldsWithCTC()}
					<div className='action-buttons' style={{ gap: 20 }}>
						<div>
							(<span style={{ color: 'red' }}>*</span>) These fields are required
						</div>
						<div>
							<Button type='primary' onClick={handleUpdate} loading={saving}>
								<SaveOutlined /> {saving ? 'Saving' : 'Save'} Proposed Data
							</Button>
						</div>
					</div>
				</div>
			</StyledFitmentDetails>
		)
	}

	const { API_USERS_FITMENTS_SHOW_STATUS } = pageState
	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (
		(accessibleFeatures?.length > 0 && !accessibleFeatures.includes('offer-edit')) ||
		fitment?.status === 'approved'
	)
		return <Redirect to={`/fitments/${fitmentId}`} />

	return (
		<div className='page-content dashboard-page'>
			<StyledBox>
				{staleWhileRevalidate({
					status: API_USERS_FITMENTS_SHOW_STATUS,
					data: fitment,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(FitmentsEditPage)
